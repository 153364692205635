import React from "react";

const SvgComponent: React.FunctionComponent = () => (
  <svg id="api-svg" data-name="Api" viewBox="0 0 185 167">
    <defs>
      <style>
        {".capability-api-2{fill:#fff}.capability-api-3{fill:#3298ff}"}
      </style>
      <linearGradient
        id="prefix__linear-gradient"
        x1={2434.38}
        y1={1888.69}
        x2={2473.01}
        y2={2011.73}
        gradientTransform="rotate(-170.72 1200.474 1132.754)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.13} stopColor="#0b74de" />
        <stop offset={0.46} stopColor="#2a6edf" />
        <stop offset={0.87} stopColor="#4d67e1" />
      </linearGradient>
    </defs>
    <title>{"API"}</title>
    <path
      d="M172.61 139.34c22.38-31.31 13-85.46-22.92-116C112.2-3.4 48.58 19 14.38 70.89c-27.85 49.53-11.19 84.89 31.53 92.39 46.23 8.11 103.09 4.18 126.7-23.94z"
      fill="url(#prefix__linear-gradient)"
    />
    <path
      className="capability-api-2"
      d="M130.91 135.27H51.77a7.34 7.34 0 0 1-7.31-7.35V79.27a7.32 7.32 0 0 1 2.14-5.2L77.32 43.2A7.27 7.27 0 0 1 82.5 41h48.41a7.34 7.34 0 0 1 7.32 7.35v79.53a7.34 7.34 0 0 1-7.32 7.39zM82.5 46.82a1.57 1.57 0 0 0-1.11.46L50.67 78.15a1.56 1.56 0 0 0-.46 1.12v48.65a1.57 1.57 0 0 0 1.56 1.57h79.14a1.57 1.57 0 0 0 1.57-1.57V48.39a1.57 1.57 0 0 0-1.57-1.57z"
    />
    <path
      className="capability-api-3"
      d="M153.27 123.48l9.53 1.77 1.62-8.85-9.53-1.77c-.14-2.67-.43-6.73-1.36-9.16l8-5.52-6.77-9.88-8 5.52c-1.93-1.73-5.6-3.45-8-4.54l1.76-9.58-8.8-1.63-1.76 9.58c-2.66.14-6.7.43-9.12 1.36l-5.49-8-9.84 6.8 5.5 8c-1.73 1.94-3.44 5.63-4.53 8.07l-9.53-1.77-1.62 8.85 9.52 1.77c.15 2.67.44 6.73 1.37 9.16l-8 5.52 6.78 9.88 8-5.53c1.93 1.75 5.6 3.47 8 4.56l-1.76 9.57 8.8 1.63 1.76-9.57c2.66-.14 6.7-.44 9.11-1.37l5.5 8 9.84-6.8-5.5-8c1.72-1.94 3.43-5.63 4.52-8.07z"
    />
    <path
      className="capability-api-3"
      d="M128 151.32a2.22 2.22 0 0 1-.37 0l-8.81-1.63a2 2 0 0 1-1.62-2.37l1.48-8a38.79 38.79 0 0 1-5.84-3.23l-6.76 4.68a2 2 0 0 1-1.51.32 2 2 0 0 1-1.3-.84l-6.77-9.88a2 2 0 0 1 .52-2.82l6.76-4.68a39.42 39.42 0 0 1-.91-6.62l-8-1.48a2.05 2.05 0 0 1-1.62-2.37l1.63-8.84a2 2 0 0 1 2.35-1.63l8 1.48a39.83 39.83 0 0 1 3.2-5.86l-4.65-6.8a2 2 0 0 1-.32-1.52 2 2 0 0 1 .84-1.3l9.83-6.8a2 2 0 0 1 2.81.52l4.65 6.79a39.33 39.33 0 0 1 6.6-.91l1.47-8a2 2 0 0 1 2.35-1.63l8.81 1.64a2 2 0 0 1 1.62 2.36l-1.48 8a38.36 38.36 0 0 1 5.84 3.22l6.76-4.68a2 2 0 0 1 2.81.52l6.77 9.88a2 2 0 0 1 .32 1.52 2 2 0 0 1-.84 1.3l-6.76 4.68a39.69 39.69 0 0 1 .91 6.63l8 1.48a2 2 0 0 1 1.3.84 2.09 2.09 0 0 1 .32 1.52l-1.63 8.85a2 2 0 0 1-2.35 1.62l-8-1.48a40 40 0 0 1-3.2 5.87l4.65 6.79a2 2 0 0 1-.52 2.82l-9.83 6.8a2 2 0 0 1-2.81-.52l-4.66-6.79a40.1 40.1 0 0 1-6.59.92l-1.47 8a2 2 0 0 1-1.98 1.63zm-6.42-5.32l4.83.89 1.4-7.58a2 2 0 0 1 1.87-1.65c1.69-.1 6.17-.34 8.5-1.24a2 2 0 0 1 2.39.74l4.35 6.35 6.51-4.5-4.35-6.35a2 2 0 0 1 .16-2.51c1.67-1.86 3.5-6 4.19-7.54a2 2 0 0 1 2.21-1.16l7.54 1.4.89-4.86-7.54-1.4a2 2 0 0 1-1.65-1.89c-.09-1.69-.34-6.18-1.23-8.54a2 2 0 0 1 .74-2.4l6.32-4.37-4.49-6.54-6.32 4.37a2 2 0 0 1-2.49-.16c-1.87-1.68-6-3.51-7.5-4.2a2 2 0 0 1-1.16-2.22l1.39-7.58-4.83-.9-1.4 7.58a2 2 0 0 1-1.91 1.7c-1.69.09-6.16.33-8.51 1.24a2 2 0 0 1-2.38-.75l-4.35-6.35-6.51 4.5 4.35 6.35a2 2 0 0 1-.16 2.51c-1.67 1.87-3.5 6-4.19 7.54a2 2 0 0 1-2.21 1.17l-7.54-1.4-.89 4.85 7.54 1.4a2 2 0 0 1 1.65 1.89c.09 1.69.34 6.2 1.23 8.54a2 2 0 0 1-.74 2.4L101 129.7l4.48 6.54 6.32-4.38a2 2 0 0 1 2.5.17c1.86 1.67 6 3.51 7.5 4.21a2 2 0 0 1 1.16 2.22z"
    />
    <ellipse cx={129.86} cy={114.57} rx={14.36} ry={14.43} fill="#00e833" />
    <ellipse
      className="capability-api-3"
      cx={65.8}
      cy={61.29}
      rx={13.86}
      ry={13.93}
    />
    <path
      className="capability-api-2"
      d="M65.77 78.11a16.77 16.77 0 0 1-15.9-11.65 16.85 16.85 0 0 1 10.79-21.18 16.73 16.73 0 0 1 21.07 10.84A16.74 16.74 0 0 1 80.7 69a16.75 16.75 0 0 1-14.93 9.11zm0-27.87a11 11 0 1 0 9.77 16.08 11.08 11.08 0 0 0-4.78-14.86 10.88 10.88 0 0 0-4.94-1.22z"
    />
    <path className="capability-api-3" d="M96.91 76.78l-29.46 30.33" />
    <path
      className="capability-api-2"
      d="M67.45 110a2.89 2.89 0 0 1-2-4.91l29.41-30.32a2.85 2.85 0 0 1 4.06 0 2.89 2.89 0 0 1 0 4.08l-29.41 30.27a2.86 2.86 0 0 1-2.06.88z"
    />
  </svg>
);

export default SvgComponent;
