module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kyma","short_name":"Kyma","start_url":"/","background_color":"#fff","theme_color":"#0073e6","display":"standalone","icon":"static/android-chrome-512x512.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-158360902-3","head":true,"anonymize":true},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    }]
