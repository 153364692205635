// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-views-landing-page-index-tsx": () => import("./../src/views/landingPage/index.tsx" /* webpackChunkName: "component---src-views-landing-page-index-tsx" */),
  "component---src-views-404-index-tsx": () => import("./../src/views/404/index.tsx" /* webpackChunkName: "component---src-views-404-index-tsx" */),
  "component---src-views-blog-root-index-tsx": () => import("./../src/views/blog/root/index.tsx" /* webpackChunkName: "component---src-views-blog-root-index-tsx" */),
  "component---src-views-blog-single-index-tsx": () => import("./../src/views/blog/single/index.tsx" /* webpackChunkName: "component---src-views-blog-single-index-tsx" */),
  "component---src-views-docs-index-tsx": () => import("./../src/views/docs/index.tsx" /* webpackChunkName: "component---src-views-docs-index-tsx" */),
  "component---src-views-community-index-tsx": () => import("./../src/views/community/index.tsx" /* webpackChunkName: "component---src-views-community-index-tsx" */),
  "component---src-views-roadmap-index-tsx": () => import("./../src/views/roadmap/index.tsx" /* webpackChunkName: "component---src-views-roadmap-index-tsx" */)
}

